<template>
  <div class="add-product">
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <b-card
            no-body
            class="full-width"
          >
            <div>
              <!--begin: Wizard-->
              <div
                class="wizard wizard-4"
                id="kt_wizard_v4"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!--begin: Wizard Nav-->
                <div
                  class="wizard-nav"
                  style="background-color: #eef0f8"
                >
                  <div class="wizard-steps">
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Thông tin chung</div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="wizard-step ml-1"
                      data-wizard-type="step"
                      v-show="showCombo"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Sản phẩm Combo</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="wizard-step ml-1"
                      data-wizard-type="step"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Bảo hành & vận chuyển</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Nav -->
                <!--begin: Wizard Body-->
                <div class="card card-custom card-shadowless rounded-top-0">
                  <div class="card-body p-0">
                    <div class="row py-8 px-8 py-lg-15 px-lg-10">
                      <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                        <form
                          class="form mt-0"
                          id="kt_form"
                        >
                          <!--begin: Thông tin chung-->
                          <div
                            class="pb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <div class="col-md-12 full-width">
                              <b-row>
                                <b-col>
                                  <h4 style="color='rgb(24, 28, 50)'">
                                    Thông tin sản phẩm
                                  </h4>
                                  <b-form-group class="required-control">
                                    <label class="d-block mt-4">
                                      Tên sản phẩm:
                                    </label>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.form.name.$model"
                                      placeholder="Nhập tên sản phẩm"
                                      :state="validateState('name')"
                                      class="mt-2"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>Yêu cầu nhập Tên sản
                                      phẩm.</b-form-invalid-feedback>
                                  </b-form-group>

                                  <b-form-group class="required-control">
                                    <label>Mã sản phẩm:</label>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.form.code.$model"
                                      placeholder="Nhập mã sản phẩm"
                                      :state="validateState('code')"
                                      class="mt-1"
                                      @change="genChildProduct"
                                      @keydown.space.prevent
                                    ></b-form-input>

                                    <b-form-invalid-feedback
                                      v-show="!$v.form.code.required &&
                                      $v.form.code.$error
                                      "
                                      id="input-product-code-required-live-feedback"
                                    >
                                      Yêu cầu nhập Mã sản phẩm.
                                    </b-form-invalid-feedback>

                                    <b-form-invalid-feedback
                                      :state="$v.form.code.maxLength"
                                      v-if="!$v.form.code.maxLength"
                                      id="input-product-code-max-lenght-live-feedback"
                                    >Vui
                                      lòng nhập mã nhỏ hơn 50 kí
                                      tự</b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                      :state="$v.form.code.minLength"
                                      v-if="!$v.form.code.minLength"
                                      id="input-product-code-min-lenght-live-feedback"
                                    >Vui
                                      lòng nhập mã lớn hơn 3 kí
                                      tự</b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.code.hasSpecialCharacters">Mã sản phẩm không
                                      được chứa các ký tự đặc
                                      biệt:
                                      <span class="font-weight-bolder bg-light-danger">
                                        '`~!@#$%^&amp;*(),;"&lt;&gt;?/\,_
                                      </span></b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.code.checkAscent">Mã sản phẩm không được chứa
                                      kí tự tiếng
                                      việt</b-form-invalid-feedback>
                                  </b-form-group>

                                  <b-form-group
                                    label="Mã vạch sản phẩm:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      id="input-2"
                                      size="sm"
                                      v-model="barcode"
                                      placeholder="Nhập mã vạch sản phẩm"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Sản phẩm cha:">
                                    <Autosuggest
                                      :model="selectedParent"
                                      :suggestions="filteredOptions"
                                      placeholder="sản phẩm cha"
                                      :limit="10"
                                      @select="
                                        onSelected('ParentProduct', $event)
                                        "
                                      @change="onInputChange"
                                      suggestionName="suggestionName"
                                    />
                                  </b-form-group>
                                  <b-form-group class="required-control">
                                    <label>Danh mục sản phẩm:</label>
                                    <Autosuggest
                                      class="mt-1"
                                      :model="$v.form.selectedCate.$model"
                                      :suggestions="filteredCateOptions"
                                      :placeholder="'danh mục sản phẩm'"
                                      :limit="200"
                                      @select="onSelected('Category', $event)"
                                      @change="onInputCateChange"
                                      :state="validateState('selectedCate')"
                                      errorMessages="Yêu cầu chọn Danh mục sản phẩm."
                                      suggestionName="suggestionName"
                                    />
                                    <span
                                      v-if="showErrorCate"
                                      class="text-danger"
                                    >
                                      Vui lòng chọn 1 mục trong Danh mục sản
                                      phẩm
                                    </span>
                                  </b-form-group>

                                  <b-form-group class="required-control">
                                    <label>Danh mục nội bộ:</label>
                                    <Autosuggest
                                      class="mt-1"
                                      :model="$v.form.selectedExternalCate.$model
                                      "
                                      :suggestions="filteredExternalCateOptions"
                                      :placeholder="'danh mục nội bộ'"
                                      :limit="200"
                                      @select="
                                        onSelected('ExternalCate', $event)
                                        "
                                      @change="onInputExternalCateChange"
                                      :state="validateState('selectedExternalCate')
    "
                                      errorMessages="Yêu cầu chọn Danh mục nội bộ."
                                      suggestionName="suggestionName"
                                    />
                                    <span
                                      v-if="showErrorExternalCate"
                                      class="text-danger"
                                    >
                                      Vui lòng chọn 1 mục trong Danh mục nội bộ
                                    </span>
                                  </b-form-group>

                                  <b-form-group
                                    label="Mã sản phẩm VAT:"
                                    style="padding: 0px"
                                    v-if="checkPermission('PRODUCT_VAT_UPSERT')"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="productCodeVat"
                                      required
                                      placeholder="Nhập mã sản phẩm VAT"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Tên sản phẩm VAT:"
                                    style="padding: 0px"
                                    v-if="checkPermission('PRODUCT_VAT_UPSERT')"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="productNameVat"
                                      required
                                      placeholder="Nhập tên sản phẩm VAT"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Tên thương mại:"
                                    class="p-0"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="productTradeName"
                                      required
                                      placeholder="Nhập tên thương mại"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    label="Mã hãng:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="productBrandCode"
                                      required
                                      placeholder="Nhập mã hãng"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Alias:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="alias"
                                      required
                                      placeholder="Nhập alias"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Lưu ý sản phẩm:"
                                    style="padding: 0px"
                                  >
                                    <b-form-textarea
                                      size="sm"
                                      v-model="note"
                                      :placeholder="'Thêm lưu ý sản phẩm'"
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                  </b-form-group>

                                  <b-form-group label="Trạng thái:">
                                    <b-form-select
                                      class="select-style"
                                      v-model="selectedStatus"
                                      :options="listStatus"
                                      required
                                      value-field="id"
                                      text-field="name"
                                      size="sm"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option
                                          :value="null"
                                          disabled
                                        >--Chọn trạng thái--
                                        </b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <div
                                    style="font-size: 14px; font-weight: 500"
                                    v-if="!selectedParent && selectedType !== 3"
                                  >
                                    <b-form-checkbox
                                      v-model="isGenChild"
                                      @change="genChildProduct()"
                                      name="check-button"
                                      switch
                                    >
                                      Tạo sản phẩm con
                                    </b-form-checkbox>
                                  </div>
                                </b-col>
                                <b-col>
                                  <h4 style="color='rgb(24, 28, 50)'">
                                    Thông tin giao dịch
                                  </h4>
                                  <b-form-group>
                                    <label class="d-block mt-4">Giá nhập:</label>
                                    <b-form-input
                                      disabled
                                      size="sm"
                                      v-model="importPrice"
                                      placeholder="Nhập giá nhập"
                                      class="text-right mt-2"
                                      @change="genChildProduct"
                                      v-mask="mask"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Giá bán lẻ:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      id="input-2"
                                      size="sm"
                                      v-model="salePrice"
                                      placeholder="Nhập giá bán lẻ"
                                      class="text-right"
                                      v-mask="mask"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Giá bán buôn:">
                                    <b-form-input
                                      size="sm"
                                      v-model="wholesalePrice"
                                      required
                                      placeholder="Nhập giá bán buôn"
                                      class="text-right"
                                      v-mask="mask"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group class="required-control">
                                    <label class="mt-1"> Giá niêm yết: </label>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.form.listedPrice.$model"
                                      placeholder="Nhập giá niêm yết"
                                      :state="validateState('listedPrice')"
                                      class="text-right"
                                      @change="genChildProduct"
                                      v-mask="formater.currency"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>Số tiền phải lớn hơn
                                      0.</b-form-invalid-feedback>
                                  </b-form-group>

                                  <b-form-group label="Giá thu lại:">
                                    <b-form-input
                                      size="sm"
                                      v-model="returnSellingPrice"
                                      placeholder="Nhập giá thu lại"
                                      class="text-right"
                                      v-mask="mask"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="VAT:">
                                    <b-form-select
                                      class="select-style"
                                      size="sm"
                                      v-model="$v.form.productVat.$model"
                                      :state="validateState('productVat')"
                                      :options="[
                                      {
                                        id: null,
                                        name: 'Chọn thuế suất',
                                      },
                                      {
                                        id: -1,
                                        name: 'KCT',
                                      },
                                      {
                                        id: 8,
                                        name: '8%',
                                      },
                                      {
                                        id: 10,
                                        name: '10%',
                                      },
                                    ]"
                                      value-field="id"
                                      text-field="name"
                                    >
                                    </b-form-select>
                                    <b-form-invalid-feedback>
                                      Yêu cầu nhập thuế suất. Thông tin liên hệ phòng kế toán.
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                  <b-form-group class="required-control">
                                    <label class="d-block mt-1">
                                      Đơn vị tính:
                                    </label>
                                    <b-form-select
                                      class="select-style"
                                      size="sm"
                                      v-model="$v.form.unitId.$model"
                                      :options="unitOptions"
                                      value-field="id"
                                      text-field="name"
                                      :state="validateState('unitId')"
                                    >
                                    </b-form-select>
                                    <b-form-invalid-feedback>Yêu cầu chọn đơn vị tính.</b-form-invalid-feedback>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <h4 style="color='rgb(24, 28, 50)'">
                                    Thuộc tính
                                  </h4>
                                  <b-form-group>
                                    <label class="d-block mt-4">Loại sản phẩm:</label>
                                    <b-form-select
                                      class="select-style"
                                      size="sm"
                                      v-model="selectedType"
                                      :options="listType"
                                      value-field="id"
                                      text-field="name"
                                      @change="onSelectType"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option
                                          :value="null"
                                          disabled
                                        >
                                          Chọn loại sản phẩm
                                        </b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>

                                  <b-form-group label="Xuất xứ:">
                                    <b-input-group>
                                      <b-form-select
                                        class="select-style"
                                        size="sm"
                                        v-model="selectedOriginal"
                                        :options="listOrginal"
                                        value-field="id"
                                        text-field="name"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option
                                            :value="null"
                                            disabled
                                          >
                                            Chọn xuất xứ
                                          </b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-input-group>
                                  </b-form-group>

                                  <b-form-group class="required-control">
                                    <label class="d-block mt-1">
                                      Thương hiệu:
                                    </label>
                                    <b-input-group>
                                      <Autosuggest
                                        :model="$v.form.searchBrandName.$model"
                                        :suggestions="filteredBrandOptions"
                                        :placeholder="'thương hiệu'"
                                        :limit="200"
                                        @select="onSelected('Brand', $event)"
                                        @change="onInputBrandChange"
                                        :state="
                                          validateState('searchBrandName')
                                        "
                                        errorMessages="Yêu cầu chọn Thương hiệu."
                                      />
                                      <b-input-group-append style="height: 2.55rem">
                                        <span
                                          @click="
                                            showBrandModel = !showBrandModel
                                          "
                                          class="input-group-text"
                                        >
                                          <i class="fas fa-plus"></i>
                                        </span>
                                      </b-input-group-append>
                                      <span
                                        v-if="showErrorBrand"
                                        class="text-danger"
                                      >
                                        Vui lòng chọn 1 mục trong Danh mục
                                        thương hiệu
                                      </span>
                                    </b-input-group>
                                  </b-form-group>

                                  <b-form-group label="Màu sắc">
                                    <b-input-group>
                                      <vue-multi-select
                                        v-model="selectedColor"
                                        :options="listColor"
                                        placeholder="Chọn màu sắc"
                                        option-label="name"
                                        v-on:input="genChildProduct"
                                      ></vue-multi-select>
                                      <b-input-group-append>
                                        <span
                                          @click="handleOpenColorModel()"
                                          class="input-group-text"
                                        >
                                          <i class="fas fa-plus"></i>
                                        </span>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>

                                  <b-form-group label="Tình trạng:">
                                    <b-input-group>
                                      <vue-multi-select
                                        v-model="selectedSize"
                                        :options="listSize"
                                        placeholder="Chọn tình trạng"
                                        option-label="name"
                                        v-on:input="genChildProduct"
                                        class="form-input"
                                      ></vue-multi-select>
                                      <b-input-group-append>
                                        <span
                                          @click="handleOpenStatusModel()"
                                          class="input-group-text"
                                        >
                                          <i class="fas fa-plus"></i>
                                        </span>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                              </b-row>

                              <div class="separator separator-dashed my-5"></div>

                              <b-row v-if="
                                  isGenChild &&
                                  !selectedParent &&
                                  selectedType !== 3
                                  ">
                                <b-col>
                                  <h4>Sản phẩm con</h4>
                                </b-col>
                                <b-col>
                                  <div
                                    class="justify-content-end"
                                    style="text-align: end"
                                  >
                                    <div class="mb-5">
                                      <b-button
                                        v-bind:style="btnCreate"
                                        class="btn btn-primary font-weight-bolder btn-sm button"
                                        v-show="checkPermission('PRODUCT_INSERT')
                                          "
                                        @click="showModalAddProduct"
                                      >
                                        <i
                                          style="font-size: 1rem"
                                          class="flaticon2-add-1"
                                        ></i>Thêm mới
                                      </b-button>
                                    </div>
                                  </div>
                                </b-col>
                              </b-row>

                              <b-table
                                v-if="isGenChild &&
                                !selectedParent &&
                                selectedType !== 3
                                "
                                :items="listProducts"
                                :fields="fields"
                                class="table-bordered table-hover col-md-12"
                              >
                                <template v-slot:cell(count)="row">
                                  <div class="d-flex justify-content-center">
                                    <span v-text="row.item.count"></span>
                                  </div>
                                </template>
                                <template
                                  v-slot:cell(status)="row"
                                  style="width: 1%"
                                >
                                  <span
                                    v-text="checkStatus(row.item.status)"
                                    class="label font-weight-bold label-lg label-inline"
                                    v-bind:class="{
                                      'label-light-warning':
                                        row.item.status === 1,
                                      'label-light-success':
                                        row.item.status === 2,
                                      'label-light-danger':
                                        row.item.status === 3,
                                      'label-light-default':
                                        row.item.status === 4,
                                    }"
                                  ></span>
                                </template>
                                <template v-slot:cell(originalPrice)="row">
                                  <div style="text-align: end">
                                    <span v-text="
                                        convertPrice(row.item.originalPrice)
                                      "></span>
                                  </div>
                                </template>
                                <template v-slot:cell(sellingPrice)="row">
                                  <div style="text-align: end">
                                    <span v-text="
                                        convertPrice(row.item.sellingPrice)
                                      "></span>
                                  </div>
                                </template>
                                <template v-slot:cell(totalQualityInstock)="row">
                                  <div style="text-align: center">
                                    <span v-text="row.item.totalQualityInstock"></span>
                                  </div>
                                </template>
                                <template v-slot:cell(actions)="row">
                                  <div class="d-flex justify-content-center">
                                    <v-icon
                                      small
                                      class="mr-2 text-primary"
                                      @click="editItem(row.item)"
                                      v-b-tooltip
                                      title="Cập nhật"
                                    >mdi-pencil</v-icon>
                                    <v-icon
                                      small
                                      class="mr-2 text-danger"
                                      @click="showDeleteAlert(row.item)"
                                      v-b-tooltip
                                      title="Xóa"
                                    >mdi-delete</v-icon>
                                  </div>
                                </template>
                              </b-table>
                            </div>
                          </div>
                          <!--end: Thông tin chung-->

                          <!--begin:Thuộc tính-->

                          <!--end:Thuộc tính-->

                          <!--begin:Sản phẩm combo-->
                          <div
                            v-show="showCombo"
                            class="pb-5 full-width"
                            data-wizard-type="step-content"
                          >
                            <div class="d-flex justify-content-between align-items-end pr-5">
                              <p>
                                <strong>Danh sách sản phẩm con</strong>
                              </p>
                              <div class="mb-5">
                                <b-button
                                  v-bind:style="btnCreate"
                                  class="btn btn-primary font-weight-bolder btn-sm button"
                                  v-show="checkPermission('PRODUCT_INSERT')"
                                  @click="showModal('add-product-combo-modal')"
                                >
                                  <i
                                    style="font-size: 1rem"
                                    class="flaticon2-add-1"
                                  ></i>Thêm mới
                                </b-button>
                              </div>
                            </div>
                            <b-table
                              v-if="listProductInCombo.length"
                              :items="listProductInCombo"
                              :fields="fieldTableCombo"
                              class="table-bordered table-hover col-md-12"
                            >
                              <template v-slot:cell(productCode)="row">
                                <div style="text-align: center">
                                  <span>{{ row.item.productCode }}</span>
                                </div>
                              </template>
                              <template v-slot:cell(productName)="row">
                                <div style="text-align: center">
                                  <span>{{ row.item.productName }}</span>
                                </div>
                              </template>
                              <template v-slot:cell(quantity)="row">
                                <div style="text-align: center">
                                  <b-form-input
                                    v-if="listProductInCombo[row.index].isEdit"
                                    type="text"
                                    v-model="listProductInCombo[row.index].quantity
                                    "
                                    size="sm"
                                    class="input-style text-right"
                                  ></b-form-input>
                                  <span v-else>{{ row.item.quantity }}</span>
                                </div>
                              </template>
                              <template v-slot:cell(productComboPrice)="row">
                                <div style="text-align: center">
                                  <b-form-input
                                    v-if="listProductInCombo[row.index].isEdit"
                                    type="text"
                                    v-model="
                                      listProductInCombo[row.index]
                                        .productComboPrice
                                    "
                                    v-mask="mask"
                                    size="sm"
                                    class="input-style text-right"
                                  ></b-form-input>
                                  <span v-else>{{
                                    row.item.productComboPrice || 0
                                  }}</span>
                                </div>
                              </template>
                              <template v-slot:cell(productComboRate)="row">
                                <div style="text-align: center">
                                  <b-form-input
                                    v-if="listProductInCombo[row.index].isEdit"
                                    type="text"
                                    v-model="
                                      listProductInCombo[row.index]
                                        .productComboRate
                                    "
                                    size="sm"
                                    class="input-style text-right"
                                  ></b-form-input>
                                  <span v-else>{{ row.item.productComboRate || 0 }}%</span>
                                </div>
                              </template>
                              <template v-slot:cell(actions)="row">
                                <div class="d-flex justify-content-center">
                                  <v-icon
                                    v-if="listProductInCombo[row.index].isEdit"
                                    class="mr-2 text-primary"
                                    small
                                    @click="updateItem(row.index)"
                                  >mdi-check</v-icon>
                                  <v-icon
                                    v-else
                                    small
                                    class="mr-2 text-warning"
                                    @click="editItemCombo(row.index, row.item)"
                                    v-b-tooltip
                                    title="Cập nhật Combo"
                                  >mdi-pencil</v-icon>
                                  <v-icon
                                    small
                                    class="mr-2 text-danger"
                                    @click="showDeleteProInComboAlert(row.item)"
                                    v-b-tooltip
                                    title="Xóa"
                                  >mdi-delete</v-icon>
                                </div>
                              </template>
                            </b-table>
                          </div>
                          <!--end:Sản phẩm combo-->

                          <!--begin:Bảo hành & vận chuyển-->
                          <div
                            class="pb-5 full-width"
                            data-wizard-type="step-content"
                          >
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-3">
                                  <h4 style="color='rgb(24, 28, 50)'">
                                    Kích thước
                                  </h4>
                                  <b-form-group
                                    label="Khối lượng:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      id="input-1"
                                      size="sm"
                                      v-model="weight"
                                      placeholder="Nhập khối lượng"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Chiều dài:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      id="input-2"
                                      size="sm"
                                      v-model="length"
                                      placeholder="Nhập chiều dài"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Chiều rộng:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="width"
                                      placeholder="Nhập chiều rộng sản phẩm"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Chiều cao:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="height"
                                      placeholder="Nhập chiều cao sản phẩm"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-3">
                                  <h4 style="color='rgb(24, 28, 50)'">
                                    Bảo hành
                                  </h4>
                                  <b-form-group
                                    label="Địa chỉ bảo hành:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="warrantyAddress"
                                      required
                                      placeholder="Nhập địa chỉ bảo hành"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Số điện thoại:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="warrantyPhone"
                                      placeholder="Nhập số điện thoại"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    label="Số tháng bảo hành:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      type="number"
                                      v-model="warrantyMonthNo"
                                      required
                                      placeholder="Nhập số tháng bảo hành"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="Nội dung:">
                                    <b-form-textarea
                                      size="sm"
                                      v-model="warrantyDescription"
                                      :placeholder="'Thêm nội dung'"
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--end:Bảo hành & vận chuyển-->
                        </form>
                        <!--end: Wizard Form-->
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Bpdy-->
              </div>
              <!--end: Wizard-->

              <b-modal
                v-model="showOriginalModel"
                hide-footer
                title="Thêm mới Xuất xứ"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">Tên xuất sứ:</label>
                    <b-form-input
                      v-model="providerName"
                      size="sm"
                      placeholder="Nhập tên xuất sứ"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      @click="createProvider()"
                    >Lưu</b-button>
                    <b-button
                      @click="showOriginalModel = !showOriginalModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                v-model="showBrandModel"
                hide-footer
                title="Thêm mới thương hiệu"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">Tên thương hiệu:</label>
                    <b-form-input
                      size="sm"
                      v-model="brandName"
                      placeholder="Nhập tên thương hiệu"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      @click="createBrand()"
                    >Lưu</b-button>
                    <b-button
                      @click="showBrandModel = !showBrandModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                v-model="showColorModel"
                hide-footer
                title="Thêm mới màu sắc"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">
                      Mã màu sắc:
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-input
                      v-model.trim="$v.color.colorCode.$model"
                      size="sm"
                      placeholder="Nhập màu sắc"
                      :state="validateStatePropColor('colorCode')"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      Vui lòng nhập mã màu.
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col>
                    <label class="labelInput">
                      Tên màu sắc:
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-input
                      v-model.trim="$v.color.colorName.$model"
                      :state="validateStatePropColor('colorName')"
                      size="sm"
                      placeholder="Nhập màu sắc"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      Vui lòng nhập tên màu.
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      @click="
                        createPropertyColor(
                          $v.color.colorName.$model,
                          $v.color.colorCode.$model,
                        )
                      "
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                    >Lưu</b-button>
                    <b-button
                      @click="showColorModel = !showColorModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                v-model="showStatusModel"
                hide-footer
                title="Thêm mới tình trạng"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">
                      Mã tình trạng:
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-input
                      v-model="$v.status.statusCode.$model"
                      size="sm"
                      placeholder="Nhập tình trạng"
                      :state="validateStatePropStatus('statusCode')"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      Vui lòng nhập mã tình trạng.
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col>
                    <label class="labelInput">
                      Tên tình trạng:
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-input
                      v-model="$v.status.statusName.$model"
                      size="sm"
                      placeholder="Nhập tình trạng"
                      :state="validateStatePropStatus('statusName')"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      Vui lòng nhập tên tình trạng.
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      @click="
                        createPropertyStatus(
                          $v.status.statusName.$model,
                          $v.status.statusCode.$model,
                        )
                      "
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                    >Lưu</b-button>
                    <b-button
                      @click="showStatusModel = !showStatusModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                ref="add-product-modal"
                hide-footer
                title="Thêm mới sản phẩm con"
                v-model="showAddProduct"
                id="modal-prevent-closing"
              >
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          label="Mã vạch sản phẩm:"
                          style="padding: 0px"
                        >
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="childBarCode"
                            placeholder="Nhập mã vạch sản phẩm"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">Nhập ít nhất 1 ký tự</b-form-invalid-feedback>
                        </b-form-group>
                        <p class="mt-4 mb-4">
                          <strong>Thuộc tính</strong>
                        </p>
                        <b-form-group label="Màu sắc:">
                          <b-form-select
                            class="select-style"
                            size="sm"
                            v-model="selectedColorChild"
                            :options="listColor"
                            value-field="id"
                            text-field="name"
                          >
                            <template v-slot:first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >-- Chọn màu sắc --</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                        <b-form-group label="Kích thước:">
                          <b-form-select
                            class="select-style"
                            size="sm"
                            v-model="selectedSizeChild"
                            :options="listSize"
                            value-field="id"
                            text-field="name"
                          >
                            <template v-slot:first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >-- Chọn kích thước --</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <p class="mt-25 mb-4">
                          <strong>Thông tin giao dịch</strong>
                        </p>
                        <b-form-group label="Giá nhập:">
                          <b-form-input
                            size="sm"
                            v-model="childOriginalPrice"
                            placeholder="Nhập giá nhập"
                            class="text-right"
                            v-mask="mask"
                            disabled
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">Nhập ít nhất 1 ký tự</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                          label="Giá bán:"
                          style="padding: 0px"
                        >
                          <b-form-input
                            size="sm"
                            v-model="childSalePrice"
                            required
                            placeholder="Nhập giá bán"
                            type="text"
                            class="text-right"
                            v-mask="mask"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-live-feedback">Nhập ít nhất 1 ký tự</b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="createChildProduct"
                  >Lưu</b-button>
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalAddProduct"
                  >Hủy</b-button>
                </v-form>
              </b-modal>
              <b-modal
                ref="update-product-modal"
                hide-footer
                title="Cập nhật sản phẩm con"
              >
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          label="Mã vạch sản phẩm:"
                          style="padding: 0px"
                        >
                          <b-form-input
                            size="sm"
                            v-model="childBarCode"
                            placeholder="Nhập mã vạch sản phẩm"
                          ></b-form-input>
                        </b-form-group>
                        <p class="mt-4 mb-4">
                          <strong>Thuộc tính</strong>
                        </p>
                        <b-form-group label="Mã sản phẩm:">
                          <b-form-input
                            size="sm"
                            v-model="childCode"
                            placeholder="Nhập mã sản phẩm"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Tên sản phẩm:">
                          <b-form-input
                            size="sm"
                            v-model="childName"
                            required
                            placeholder="Nhập tên sản phẩm"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <p class="mt-25 mb-4">
                          <strong>Thông tin giao dịch</strong>
                        </p>
                        <b-form-group label="Giá nhập:">
                          <b-form-input
                            id="input-1"
                            size="sm"
                            v-model="childOriginalPrice"
                            placeholder="Nhập giá nhập"
                            type="text"
                            class="text-right"
                            v-mask="mask"
                            disabled
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="Giá bán:"
                          style="padding: 0px"
                        >
                          <b-form-input
                            size="sm"
                            v-model="childSalePrice"
                            placeholder="Nhập giá bán"
                            type="text"
                            class="text-right"
                            v-mask="mask"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="updateItem"
                  >Lưu</b-button>
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalUpdateProduct"
                  >Hủy</b-button>
                </v-form>
              </b-modal>
              <b-modal
                ref="add-product-combo-modal"
                hide-footer
                title="Thêm mới sản phẩm con cho combo"
              >
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <b-row>
                    <b-col>
                      <b-form-group label="Chọn sản phẩm con:">
                        <Autosuggest
                          :model="searchProductForCombo"
                          :suggestions="filteredOptions"
                          placeholder="sản phẩm con"
                          :limit="10"
                          @select="onSelected('ProductForCombo', $event)"
                          @change="onInputChangeProductCombo"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Số lượng:"
                        style="padding: 0px"
                      >
                        <b-form-input
                          size="sm"
                          v-model="proComboQuantity"
                          placeholder="Nhập SL"
                          type="number"
                          class="text-right"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">Nhập ít nhất 1 ký tự</b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Nhập giá tiền combo:">
                        <b-form-input
                          size="sm"
                          v-model="productComboPrice"
                          placeholder="Giá tiền combo"
                          type="text"
                          v-mask="mask"
                          class="input-style text-right"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">Nhập ít nhất 1 ký tự</b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Nhập tỷ lệ phân bổ:"
                        style="padding: 0px"
                      >
                        <b-input-group
                          size="sm"
                          append="%"
                        >
                          <b-form-input
                            v-model="productComboRate"
                            placeholder="Tỷ lệ phân bổ"
                            class="input-style text-right"
                            type="text"
                          ></b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback id="input-live-feedback">Nhập ít nhất 1 ký tự</b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="addProductToCombo"
                  >Thêm</b-button>
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalAddProductCombo"
                  >Hủy</b-button>
                </v-form>
              </b-modal>
            </div>
          </b-card>
        </v-form>
      </template>
      <template v-slot:foot>
        <div class="col-md-12 full-width">
          <div class="row">
            <div class="col-md-3">
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="debounceCreateProduct"
              >Lưu</b-button>
              <router-link
                to="/products"
                tag="button"
              >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                >Hủy</b-button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- </b-col> -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import debounce from 'debounce';
import toNumber from 'lodash/toNumber';
import VueMultiSelect from 'vue-simple-multi-select';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '@/utils/saveDataToLocal';
import KTCodePreview from '@/view/content/CodeTab.vue';
import KTUtil from '@/assets/js/components/util';
import KTWizard from '@/assets/js/components/wizard';
import {
  convertPrice,
  currencyMask,
  genMultilineRegexp,
  removeAccents,
  unMaskPrice,
} from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';
import { getToastInstance } from '@/utils/toastHelper';
import { UNIT_OPTIONS } from '@/utils/types';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

const checkCurrency = (value) => {
  const money = unMaskPrice(value);
  const moneyDec = toNumber(money);
  return moneyDec > 0;
};

const hasSpecialCharacters = (string) => {
  const regexSpecialCharacters = /[\s~`!@#$%^&*+=[\]\\';,/{}|\\":<>?()._]/g;
  const normalizedString = string.normalize().trim();
  return !regexSpecialCharacters.test(normalizedString);
};

const checkAscent = (string) => {
  const regexAscent = genMultilineRegexp(
    [
      'à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ',
      '|è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ',
      '|ì|í|ị|ỉ|ĩ',
      '|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ',
      '|ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ',
      '|ỳ|ý|ỵ|ỷ|ỹ',
      '|đ',
    ],
    'g',
  );
  return !regexAscent.test(string);
};

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      code: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(3),
        hasSpecialCharacters,
        checkAscent,
      },
      name: {
        required,
      },
      selectedCate: {
        required,
      },
      selectedExternalCate: {
        required,
      },
      searchBrandName: {
        required,
      },
      listedPrice: {
        required,
        checkCurrency,
      },
      unitId: {
        required,
      },
      productVat: {
        required,
      },
    },
    color: {
      colorCode: {
        required,
      },
      colorName: {
        required,
      },
    },
    status: {
      statusCode: {
        required,
      },
      statusName: {
        required,
      },
    },
  },
  data() {
    return {
      showErrorCate: false,
      showErrorExternalCate: false,
      showErrorBrand: false,
      form: {
        name: '',
        code: '',
        category: '',
        listedPrice: 0,
        selectedCate: '',
        selectedExternalCate: '',
        searchBrandName: '',
        unitId: null,
        productVat: null,
      },
      color: {
        colorCode: '',
        colorName: '',
      },
      status: {
        statusCode: '',
        statusName: '',
      },
      errors: [],
      productCodeVat: '',
      productNameVat: '',
      productTradeName: '',
      productBrandCode: '',
      alias: '',
      productVat: null,
      returnSellingPrice: 0,
      productPropertyId: '',
      colorName: '',
      statusName: '',
      colorCode: '',
      statusCode: '',
      brandName: '',
      providerName: '',
      btnCreate: {
        fontWeight: '600!important',
      },
      showOriginalModel: false,
      showBrandModel: false,
      showColorModel: false,
      showStatusModel: false,
      mask: currencyMask,
      options: [
        {
          data: [],
        },
      ],
      cateOptions: [],
      filteredOptions: [],
      filteredCateOptions: [],
      limit: 10,
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        { key: 'actions', label: '' },
      ],
      listProducts: [],
      fieldTableCombo: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center', width: '20%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center', width: '30%' },
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          sortable: false,
          thStyle: { textAlign: 'center', width: '14%' },
        },
        {
          key: 'productComboPrice',
          label: 'Giá tiền combo',
          sortable: false,
          thStyle: { textAlign: 'center', width: '14%' },
        },
        {
          key: 'productComboRate',
          label: 'Tỷ lệ phân bổ ',
          sortable: false,
          thStyle: { textAlign: 'center', width: '14%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: { textAlign: 'center', width: '14%' },
        },
      ],
      valid: true,
      barcode: '',
      importPrice: 0,
      salePrice: 0,
      warrantyAddress: '',
      warrantyPhone: '',
      warrantyMonthNo: 0,
      warrantyDescription: '',
      width: 0,
      height: 0,
      length: 0,
      weight: 0,
      selectedParent: '',
      listColor: [],
      selectedColor: [],
      listSize: [],
      selectedSize: [],
      selectedType: 1,
      listType: [
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
        { id: 3, name: 'Combo' },
        { id: 4, name: 'Sản phẩm dịch vụ' },
      ],
      selectedOriginal: null,
      listOrginal: [],
      selectedBrand: null,
      listBrand: [],
      childCode: '',
      childName: '',
      childId: 0,
      childBarCode: '',
      childOriginalPrice: 0,
      childSalePrice: 0,
      productComboPrice: '',
      productComboRate: '',
      selectedColorChild: null,
      selectedSizeChild: null,
      proComboQuantity: 1,
      listProductInCombo: [],
      showCombo: false,
      selectProductComboId: 0,
      selectedStatus: 1,
      listStatus: [
        { id: 1, name: 'Mới' },
        { id: 2, name: 'Đang bán' },
        { id: 3, name: 'Ngừng bán' },
        { id: 4, name: 'Hết hàng' },
      ],
      listResultSearch: [],
      showAddProduct: false,
      externalCateOptions: [],
      filteredExternalCateOptions: [],
      selectedClassParent: null,
      selectedExternalCateId: null,
      selectedCateId: null,
      selectedProductForCombo: {
        id: null,
        code: '',
        name: '',
        quantity: 0,
      },
      searchProductForCombo: '',
      wholesalePrice: 0,
      isSearching: false,
      isGenChild: false,
      note: '',
      brandOptions: [],
      filteredBrandOptions: [],
      formater: {
        currency: currencyMask,
      },
      unitOptions: UNIT_OPTIONS,
    };
  },
  components: {
    KTCodePreview,
    VueMultiSelect,
    Autosuggest,
  },
  computed: {},
  created() {
    this.getListCate();
    this.getListExternalCate();
    this.fetchBrand();
    this.fetchProvider();
    this.getColorProperty();
    this.getSizeProperty();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Danh sách sản phẩm', route: '/products' },
      { title: 'Thêm mới sản phẩm' },
    ]);

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    ...getToastInstance(),
    convertPrice,
    handleOpenColorModel() {
      this.showColorModel = !this.showColorModel;
      this.productPropertyId = this.listColor[0].productPropertyId;
      this.$v.color.colorCode.$model = '';
      this.$v.color.colorName.$model = '';
      this.$v.color.$reset();
    },
    handleOpenStatusModel() {
      this.showStatusModel = !this.showStatusModel;
      this.productPropertyId = this.listSize[0].productPropertyId;
      this.$v.status.statusCode.$model = '';
      this.$v.status.statusName.$model = '';
      this.$v.status.$reset();
    },
    onSelected(type, option) {
      switch (type) {
        case 'ParentProduct': {
          this.selectedClassParent = option.item.id;
          let name = option.item.name;
          if (option.item.code !== '') {
            name = option.item.code + ' - ' + option.item.name;
          }
          this.selectedParent = name;
          this.genChildProduct();
          break;
        }
        case 'ProductForCombo': {
          this.selectedProductForCombo.id = option.item.id;
          this.selectedProductForCombo.name = option.item.name;
          this.selectedProductForCombo.code = option.item.code;
          this.searchProductForCombo = option.item.name;
          break;
        }
        case 'Category': {
          this.selectedCateId = option.item.id;
          this.form.selectedCate = option.item.name;
          break;
        }
        case 'ExternalCate': {
          this.selectedExternalCateId = option.item.id;
          this.form.selectedExternalCate = option.item.name;
          break;
        }
        case 'Brand': {
          this.selectedBrand = option.item.id;
          this.form.searchBrandName = option.item.name;
          break;
        }
        default:
          break;
      }
      if (this.selectedCateId) {
        this.showErrorCate = false;
      }
      if (this.selectedExternalCateId) {
        this.showErrorExternalCate = false;
      }
      if (this.selectedBrand) {
        this.showErrorBrand = false;
      }
    },
    onInputChange(text) {
      this.selectedParent = text;
      this.debounceInputSearchProduct();
      this.genChildProduct();
    },
    onInputChangeProductCombo(text) {
      this.searchProductForCombo = text;

      this.debounceInputSearchProductCombo();
    },
    onInputCateChange(text) {
      this.form.selectedCate = text;

      const filteredData = this.cateOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [...filteredData];
      if (this.form.selectedCate === '') {
        this.filteredCateOptions = [...this.cateOptions];
      }
      if (!this.form.selectedCate) {
        this.selectedCateId = null;
        this.showErrorCate = false;
      }
    },
    onInputBrandChange(text) {
      this.form.searchBrandName = text;

      const filteredData = this.brandOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredBrandOptions = [...filteredData];
      if (this.form.searchBrandName === '') {
        this.filteredBrandOptions = [...this.brandOptions];
      }
      if (!this.form.searchBrandName) {
        this.selectedBrand = null;
        this.showErrorBrand = false;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStatePropColor(name) {
      const { $dirty, $error } = this.$v.color[name];
      return $dirty ? !$error : null;
    },
    validateStatePropStatus(name) {
      const { $dirty, $error } = this.$v.status[name];
      return $dirty ? !$error : null;
    },
    genChildProduct() {
      this.listProducts = [];
      if (!this.isGenChild || this.selectedParent || this.selectedType === 3) {
        this.listProducts = [];
        return;
      }

      let count = 1;
      if (this.selectedColor.length === 0 && this.selectedSize.length > 0) {
        for (let i = 0; i < this.selectedSize.length; i++) {
          const size = this.selectedSize[i];
          const product = {
            id: this.$v.form.name.$model + '-' + size.code,
            count: count,
            productName: this.$v.form.name.$model + ' - ' + size.name,
            productCode: this.$v.form.code.$model + '-' + size.code,
            productCodeVat: '',
            productNameVat: '',
            productTradeName: '',
            productBrandCode: '',
            alias: '',
            parentProductId: this.selectedClassParent,
            barCode: '',
            original: this.selectedOriginal,
            status: this.selectedStatus,
            productType: this.selectedType,
            brand: this.selectedBrand,
            originalPrice: this.importPrice ? unMaskPrice(this.importPrice) : 0,
            sellingPrice: this.salePrice ? unMaskPrice(this.salePrice) : 0,
            wholesalePrice: this.wholesalePrice
              ? unMaskPrice(this.wholesalePrice)
              : 0,
            listedPrice: this.form.listedPrice
              ? unMaskPrice(this.form.listedPrice)
              : 0,
            totalQualityInstock: 0,
            productCategory: this.$v.form.selectedCate.$model,
            note: this.note,
            color: null,
            size: size.id,
          };
          count++;
          this.listProducts.push(product);
        }
      } else if (
        this.selectedSize.length === 0 &&
        this.selectedColor.length > 0
      ) {
        for (let i = 0; i < this.selectedColor.length; i++) {
          const color = this.selectedColor[i];
          const product = {
            id: this.$v.form.code.$model + '-' + color.code,
            count: count,
            productName: this.$v.form.name.$model + ' - ' + color.name,
            productCode: this.$v.form.code.$model + '-' + color.code,
            productNameVat: '',
            productCodeVat: '',
            productTradeName: '',
            productBrandCode: '',
            alias: '',
            parentProductId: this.selectedClassParent,
            barCode: '',
            original: this.selectedOriginal,
            status: this.selectedStatus,
            productType: this.selectedType,
            brand: this.selectedBrand,
            originalPrice: this.importPrice ? unMaskPrice(this.importPrice) : 0,
            sellingPrice: this.salePrice ? unMaskPrice(this.salePrice) : 0,
            wholesalePrice: this.wholesalePrice
              ? unMaskPrice(this.wholesalePrice)
              : 0,
            listedPrice: this.form.listedPrice
              ? unMaskPrice(this.form.listedPrice)
              : 0,
            totalQualityInstock: 0,
            productCategory: this.$v.form.selectedCate.$model,
            note: this.note,
            color: color.id,
            size: null,
          };
          count++;
          this.listProducts.push(product);
        }
      } else {
        for (let index = 0; index < this.selectedColor.length; index++) {
          const color = this.selectedColor[index];
          for (let i = 0; i < this.selectedSize.length; i++) {
            const size = this.selectedSize[i];
            const product = {
              id: this.$v.form.code.$model + '-' + color.code + '-' + size.code,
              count: count,
              productName:
                this.$v.form.name.$model +
                ' - ' +
                color.name +
                ' - ' +
                size.name,
              productCode:
                this.$v.form.code.$model + '-' + color.code + '-' + size.code,
              productNameVat: '',
              productCodeVat: '',
              productTradeName: '',
              productBrandCode: '',
              alias: '',
              parentProductId: this.selectedClassParent,
              barCode: '',
              original: this.selectedOriginal,
              status: this.selectedStatus,
              productType: this.selectedType,
              brand: this.selectedBrand,
              originalPrice: this.importPrice
                ? unMaskPrice(this.importPrice)
                : 0,
              sellingPrice: this.salePrice ? unMaskPrice(this.salePrice) : 0,
              wholesalePrice: this.wholesalePrice
                ? unMaskPrice(this.wholesalePrice)
                : 0,
              listedPrice: this.form.listedPrice
                ? unMaskPrice(this.form.listedPrice)
                : 0,
              totalQualityInstock: 0,
              productCategory: this.$v.form.selectedCate.$model,
              note: this.note,
              color: color.id,
              size: size.id,
            };
            count++;
            this.listProducts.push(product);
          }
        }
      }
    },
    checkStatus: function (item) {
      const status = {
        1: 'Mới',
        2: 'Đang bán',
        3: 'Ngừng bán',
        4: 'Hết hàng',
      };
      return status[item] || '';
    },
    onInputExternalCateChange(text) {
      this.form.selectedExternalCate = text;

      const filteredData = this.externalCateOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredExternalCateOptions = [...filteredData];
      if (this.form.selectedExternalCate === '') {
        this.filteredExternalCateOptions = [...this.externalCateOptions];
      }
      if (!this.form.selectedExternalCate) {
        this.selectedExternalCateId = null;
        this.showErrorExternal = false;
      }
    },
    debounceInputSearchProduct: debounce(function () {
      this.searchProductAPI(1);
    }, TIME_TRIGGER),
    debounceInputSearchProductCombo: debounce(function () {
      this.searchProductAPI(2);
    }, TIME_TRIGGER),
    searchProductAPI(type) {
      if (!this.isSearching) {
        let textSearch = '';
        if (type === 1) {
          textSearch = this.selectedParent ? this.selectedParent.trim() : '';
        } else if (type === 2) {
          textSearch = this.searchProductForCombo
            ? this.searchProductForCombo.trim()
            : '';
        }
        this.searchProduct(textSearch);
      }
    },
    createProduct: function () {
      if (!this.isGenChild) {
        this.listProducts = [];
      }
      if (this.$v.form.$anyError) {
        return;
      }

      let listPro = [];

      const productName = this.$v.form.name.$model
        ? this.$v.form.name.$model.trim()
        : null;
      const productCode = this.$v.form.code.$model
        ? this.$v.form.code.$model.trim()
        : null;

      if (
        !this.$v.form.selectedCate.$model ||
        !this.$v.form.selectedCate.$model.trim()
      ) {
        this.selectedCateId = null;
      }

      if (
        !this.$v.form.selectedExternalCate.$model ||
        !this.$v.form.selectedExternalCate.$model.trim()
      ) {
        this.selectedExternalCateId = null;
      }

      if (
        !this.$v.form.searchBrandName.$model ||
        !this.$v.form.searchBrandName.$model.trim()
      ) {
        this.selectedBrand = null;
      }

      let color = null;
      let size = null;
      if (!this.isGenChild) {
        color = this.selectedColor.length > 0 ? this.selectedColor[0].id : null;
        size = this.selectedSize.length > 0 ? this.selectedSize[0].id : null;
      }
      if (!this.selectedCateId) {
        this.showErrorCate = true;
      }
      if (!this.selectedExternalCateId) {
        this.showErrorExternalCate = true;
      }
      if (!this.selectedBrand) {
        this.showErrorBrand = true;
      }
      if (
        this.selectedCateId &&
        this.selectedExternalCateId &&
        this.selectedBrand
      ) {
        const product = {
          id: this.$uuid.v4(),
          accessories: [],
          productName: productName,
          productCode: productCode,
          productCodeVat: this.productCodeVat
            ? this.productCodeVat.trim()
            : null,
          productNameVat: this.productNameVat
            ? this.productNameVat.trim()
            : null,
          productTradeName: this.productTradeName
            ? this.productTradeName.trim()
            : null,
          productBrandCode: this.productBrandCode
            ? this.productBrandCode.trim()
            : null,
          alias: this.alias && this.alias.trim(),
          barCode: this.barcode ? this.barcode.trim() : '',
          parentProductId: this.selectedClassParent,
          productCategory: this.selectedCateId,
          productWebCategory: this.selectedExternalCateId,
          originalPrice: this.importPrice ? unMaskPrice(this.importPrice) : 0,
          sellingPrice: this.salePrice ? unMaskPrice(this.salePrice) : 0,
          listedPrice: this.form.listedPrice
            ? unMaskPrice(this.form.listedPrice)
            : 0,
          wholesalePrice: this.wholesalePrice
            ? unMaskPrice(this.wholesalePrice)
            : 0,
          returnSellingPrice: this.returnSellingPrice
            ? unMaskPrice(this.returnSellingPrice)
            : 0,
          unitId: this.form.unitId || null,
          productVat: this.form.productVat || null,
          productType: this.selectedType,
          original: this.selectedOriginal,
          brand: this.selectedBrand,
          color: color,
          size: size,
          weight: this.weight,
          length: this.length,
          width: this.width,
          height: this.height,
          warrantyAddress: this.warrantyAddress,
          warrantyPhone: this.warrantyPhone,
          warrantyMonthNo: this.warrantyMonthNo,
          warrantyDescription: this.warrantyDescription,
          status: this.selectedStatus,
          note: this.note,
          deleted: 0,
        };
        listPro.push(product);
        this.listProducts.length > 0
          ? this.listProducts.forEach((element) => {
              const pro = {
                id: this.$uuid.v4(),
                productName: element.productName
                  ? element.productName.trim()
                  : null,
                productCode: element.productCode
                  ? element.productCode.trim()
                  : null,
                barCode: element.barCode ? element.barCode.trim() : '',
                parentProductId: product.id,
                productCategory: this.selectedCateId,
                productWebCategory: this.selectedExternalCateId,
                originalPrice: element.originalPrice
                  ? unMaskPrice(element.originalPrice)
                  : 0,
                sellingPrice: element.sellingPrice
                  ? unMaskPrice(element.sellingPrice)
                  : 0,
                wholesalePrice: this.wholesalePrice
                  ? unMaskPrice(this.wholesalePrice)
                  : 0,
                returnSellingPrice: this.returnSellingPrice
                  ? unMaskPrice(this.returnSellingPrice)
                  : 0,
                unitId: this.form.unitId || null,
                listedPrice: this.form.listedPrice
                  ? unMaskPrice(this.form.listedPrice)
                  : 0,
                productType: this.selectedType,
                original: element.original,
                brand: element.brand,
                color: element.color,
                size: element.size,
                weight: this.weight,
                length: this.length,
                width: this.width,
                height: this.height,
                warrantyAddress: this.warrantyAddress,
                warrantyPhone: this.warrantyPhone,
                warrantyMonthNo: this.warrantyMonthNo,
                warrantyDescription: this.warrantyDescription,
                status: this.selectedStatus,
                deleted: 0,
                productCodeVat: element.productCodeVat
                  ? element.productCodeVat.trim()
                  : null,
                productNameVat: element.productNameVat
                  ? element.productNameVat.trim()
                  : null,
                productTradeName: element.productTradeName
                  ? element.productTradeName.trim()
                  : null,
                productBrandCode: element.productBrandCode
                  ? element.productBrandCode.trim()
                  : null,
                note: this.note,
                productVat: this.form.productVat,
              };
              listPro.push(pro);
            })
          : [];
        const data = {
          listProduct: listPro,
        };
        ApiService.post('product/create-list-product', data)
          .then(({ data }) => {
            if (data.status === 1) {
              this.makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({ name: 'list-products' });
              }, TIME_TRIGGER);
            } else {
              this.makeToastFailure(data.message);
            }
          })
          .catch(({ response }) => {
            this.makeToastFailure(response.$error);
          });
      }
    },
    sumComboPrice() {
      return this.listProductInCombo.reduce((sum, productInCombo) => {
        return sum + toNumber(unMaskPrice(productInCombo.productComboPrice));
      }, 0);
    },
    createProductCombo: function () {
      if (this.$v.form.$anyError) {
        this.makeToastFailure('Thông tin sản phẩm không hợp lệ!');
        return;
      }
      const sumComboPrice = this.sumComboPrice();

      if (toNumber(unMaskPrice(this.salePrice)) !== sumComboPrice) {
        this.makeToastFailure('Giá bán lẻ không bằng tổng giá sản phẩm combo!');
        return;
      }

      this.listProductInCombo = this.listProductInCombo
        ? this.listProductInCombo.map((item) => {
            return {
              ...item,
              productComboPrice: unMaskPrice(item.productComboPrice),
            };
          })
        : [];

      const data = {
        id: this.$uuid.v4(),
        productName: this.$v.form.name.$model,
        productCode: this.$v.form.code.$model,
        barCode: this.barcode,
        parentProductId: null,
        productCategory: this.selectedCateId,
        productWebCategory: this.selectedExternalCateId,
        originalPrice: unMaskPrice(this.importPrice),
        sellingPrice: unMaskPrice(this.salePrice),
        listedPrice: this.form.listedPrice
          ? unMaskPrice(this.form.listedPrice)
          : 0,
        productType: this.selectedType,
        original: this.selectedOriginal,
        brand: this.selectedBrand,
        color: null,
        size: null,
        weight: this.weight,
        length: this.length,
        width: this.width,
        height: this.height,
        warrantyAddress: this.warrantyAddress,
        warrantyPhone: this.warrantyPhone,
        warrantyMonthNo: this.warrantyMonthNo,
        warrantyDescription: this.warrantyDescription,
        status: this.selectedStatus,
        comboItems: this.listProductInCombo,
        note: this.note,
      };
      ApiService.post('product/create-product', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                path: '/products',
              });
            }, TIME_TRIGGER);
          } else {
            this.makeToastFailure(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    createPropertyColor: function (propName, propCode) {
      this.$v.color.$touch();
      if (this.$v.color.$anyError) {
        return;
      }
      const data = {
        name: propName,
        code: propCode,
        productPropertyId: this.productPropertyId,
      };
      ApiService.post('propertyValue/create', data).then(({ data }) => {
        if (data.status === 1) {
          this.getColorProperty();
          this.getSizeProperty();
          this.showColorModel = false;
          this.showStatusModel = false;
          this.makeToastSuccess(data.message);
        } else {
          this.makeToastFailure(data.message);
        }
      });
    },
    createPropertyStatus: function (propName, propCode) {
      this.$v.status.$touch();
      if (this.$v.status.$anyError) {
        return;
      }
      const data = {
        name: propName,
        code: propCode,
        productPropertyId: this.productPropertyId,
      };
      ApiService.post('propertyValue/create', data).then(({ data }) => {
        if (data.status === 1) {
          this.getColorProperty();
          this.getSizeProperty();
          this.showColorModel = false;
          this.showStatusModel = false;
          this.makeToastSuccess(data.message);
        } else {
          this.makeToastFailure(data.message);
        }
      });
    },
    createProvider: function () {
      if (!this.providerName) {
        return;
      }
      const provider = {
        name: this.providerName,
      };

      const paramQuery = {
        params: provider,
      };
      ApiService.post('providers', paramQuery).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchProvider();
          this.showOriginalModel = !this.showOriginalModel;
          this.makeToastSuccess(message);
        } else {
          this.showOriginalModel = !this.showOriginalModel;
          this.makeToastFailure(message);
        }
      });
    },
    createBrand: function () {
      if (!this.brandName) {
        return;
      }
      const paramQuery = {
        params: { id: this.$uuid.v4(), name: this.brandName },
      };
      ApiService.post('brands', paramQuery).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchBrand();
          this.showBrandModel = !this.showBrandModel;
          this.makeToastSuccess(message);
        } else {
          this.showBrandModel = !this.showBrandModel;
          this.makeToastFailure(message);
        }
      });
    },
    getListCate: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.numberOfPage = data.data.total_page;
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          const item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions];
      });
    },
    getListExternalCate: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('productExternalCategory', paramQuery).then(
        ({ data }) => {
          this.numberOfPage = data.data.total_page;
          data.data.list_caterogy.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '-' + element.name;
            } else if (element.level === 2) {
              name = '--' + element.name;
            } else if (element.level === 3) {
              name = '---' + element.name;
            } else if (element.level === 4) {
              name = '----' + element.name;
            } else if (element.level === 5) {
              name = '-----' + element.name;
            } else if (element.level === 6) {
              name = '------' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.externalCateOptions.push(item);
          });
          this.filteredExternalCateOptions = [...this.externalCateOptions];
        },
      );
    },
    fetchBrand: function () {
      this.listBrand = [];
      const param = {
        page: 1,
        pageSize: 1000,
        name: '',
      };
      const paramQuery = {
        params: {
          param,
        },
      };
      ApiService.query('brands', paramQuery).then((response) => {
        response.data.brands.forEach((item) => {
          const brand = {
            id: item.id,
            name: item.name,
          };
          this.listBrand.push(brand);
        });
        this.brandOptions = response.data.brands;
        this.filteredBrandOptions = [...this.brandOptions];
      });
    },
    fetchProvider: function () {
      this.listOrginal = [];
      ApiService.get('city/country').then((response) => {
        response.data.data.forEach((item) => {
          let provider = {
            id: item.id,
            name: item.name,
          };
          this.listOrginal.push(provider);
        });
      });
    },
    getColorProperty: function () {
      ApiService.get(`property/getByType/1`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listColor = data.data;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    getSizeProperty: function () {
      ApiService.get(`property/getByType/2`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listSize = data.data;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    searchProduct: function (textSearch) {
      const param = {
        name: textSearch,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('product/searchProductByName', paramQuery).then(
        (response) => {
          this.options[0].data = [];
          response.data.data.forEach((item) => {
            item.productCode = item.productCode ? item.productCode : '';
            let suggestionName = item.productName;
            if (item.productCode !== '') {
              suggestionName = item.productCode + ' - ' + item.productName;
            }
            const product = {
              id: item.id,
              name: item.productName,
              code: item.productCode,
              suggestionName: suggestionName,
            };
            this.options[0].data.push(product);
          });
          this.filteredOptions = [...this.options[0].data];
        },
      );
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    showModalAddProduct() {
      this.selectedParent = '';
      this.childBarCode = '';
      this.showAddProduct = true;
      this.$refs['add-product-modal'].show();
    },
    hideModalAddProduct() {
      this.$refs['add-product-modal'].hide();
    },
    showModalUpdateProduct() {
      this.selectedParent = '';
      this.$refs['update-product-modal'].show();
    },
    hideModalUpdateProduct() {
      this.$refs['update-product-modal'].hide();
    },
    editItem(item) {
      this.childId = item.id;
      this.childCode = item.productCode;
      this.childName = item.productName;
      this.childBarCode = item.barCode;
      this.childOriginalPrice = unMaskPrice(item.originalPrice);
      this.childSalePrice = unMaskPrice(item.sellingPrice);
      this.showModalUpdateProduct();
    },
    updateItem(index) {
      this.listProducts.forEach((element) => {
        if (element.id === this.childId) {
          element.productCode = this.childCode;
          element.productName = this.childName;
          element.barCode = this.childBarCode;
          element.originalPrice = unMaskPrice(this.childOriginalPrice);
          element.sellingPrice = unMaskPrice(this.childSalePrice);
        }
      });
      this.hideModalUpdateProduct();
      this.listProductInCombo[index].isEdit =
        !this.listProductInCombo[index].isEdit;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm con!',
        text: 'Bạn có chắc muốn xóa sản phẩm con này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteChildProduct(item.id);
        }
      });
    },
    deleteChildProduct(id) {
      for (var i = 0; i < this.listProducts.length; i++) {
        if (this.listProducts[i].id === id) {
          this.listProducts.splice(i, 1);
        }
      }
    },
    createChildProduct() {
      this.showAddProduct = false;
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
      let colorName = '';
      let colorCode = '';
      let sizeName = '';
      let sizeCode = '';
      this.listColor.forEach((element) => {
        if (element.id === this.selectedColorChild) {
          colorName = element.name;
          colorCode = element.code;
        }
      });
      this.listSize.forEach((element) => {
        if (element.id === this.selectedSizeChild) {
          sizeName = element.name;
          sizeCode = element.code;
        }
      });
      let count = 1;
      if (this.listProducts.length > 0) {
        count = this.listProducts[this.listProducts.length - 1].count + 1;
      }
      let product = {
        id: this.$v.form.code.$model + '-' + colorCode + '-' + sizeCode,
        count: count,
        productName:
          this.$v.form.name.$model + ' - ' + colorName + ' - ' + sizeName,
        productCode:
          this.$v.form.code.$model + '-' + colorCode + '-' + sizeCode,
        parentProductId: this.selectedClassParent,
        barCode: this.childBarCode,
        original: this.selectedOriginal,
        status: this.selectedStatus,
        productType: this.selectedType,
        brand: this.selectedBrand,
        productVat:  this.$v.form.productVat.$model,
        unitId:  this.$v.form.unitId.$model,
        originalPrice: unMaskPrice(this.childOriginalPrice),
        sellingPrice: unMaskPrice(this.childSalePrice),
        totalQualityInstock: 0,
        productCategory: this.$v.form.selectedCate.$model,
        color: this.selectedColorChild,
        size: this.selectedSizeChild,
        note: this.note,
      };
      this.listProducts.push(product);
    },
    showModal(elementId) {
      if (elementId.indexOf('add') !== -1) {
        this.searchProductForCombo = '';
        this.proComboQuantity = 1;
        this.productComboRate = '';
        this.productComboPrice = '';
      }
      this.$refs[`${elementId}`].show();
    },
    hideModal(elementId) {
      this.$refs[`${elementId}`].hide();
    },
    hideModalAddProductCombo() {
      this.$refs['add-product-combo-modal'].hide();
    },

    addProductToCombo() {
      const pro = {
        id: this.selectedProductForCombo.id,
        productCode: this.selectedProductForCombo.code,
        productName: this.selectedProductForCombo.name,
        quantity: this.proComboQuantity ? this.proComboQuantity : 1,
        productComboPrice: this.productComboPrice,
        productComboRate: this.productComboRate,
        isEdit: false,
      };
      const duplicateIndex = this.listProductInCombo.findIndex(
        (element) => element.id === pro.id,
      );
      if (duplicateIndex !== -1) {
        this.makeToastFailure('Sản phẩm trùng!');
        return;
      }
      this.hideModalAddProductCombo();
      this.listProductInCombo.push(pro);
    },
    onSelectType() {
      if (this.selectedType === 3) {
        this.genChildProduct();
        this.showCombo = true;
      } else {
        this.showCombo = false;
      }
    },
    editItemCombo(index, item) {
      for (let index = 0; index < this.listProductInCombo.length; index++) {
        const element = this.listProductInCombo[index];
        if ((element.id = item.id)) {
          this.selectProductComboId = index;
        }
      }
      this.selectedProductForCombo.id = item.id;
      this.selectedProductForCombo.name = item.productName;
      this.selectedProductForCombo.code = item.productCode;
      this.proComboQuantity = item.quantity;
      this.searchProductForCombo = item.productName;
      this.listProductInCombo[index].isEdit =
        !this.listProductInCombo[index].isEdit;
    },
    showDeleteProInComboAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm con của commo!',
        text: 'Bạn có chắc muốn xóa sản phẩm con này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteChildProductCombo(item.id);
        }
      });
    },
    deleteChildProductCombo(id) {
      for (var i = 0; i < this.listProductInCombo.length; i++) {
        if (this.listProductInCombo[i].id === id) {
          this.listProductInCombo.splice(i, 1);
        }
      }
    },
    debounceCreateProduct: debounce(function () {
      console.log('aaaa');
      this.onClickToCreate();
    }, TIME_TRIGGER),
    onClickToCreate() {
      this.$v.form.$touch();
      this.selectedType === 3
        ? this.createProductCombo()
        : this.createProduct();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
<style lang="scss">
.add-product {
  .input-group-append {
    cursor: pointer;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }

  .inputText {
    width: 22%;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .card-header-tabs {
    margin-right: -21px !important;
    margin-left: -21px !important;
  }

  .full-width .nav-tabs .nav-item {
    margin-bottom: -1px;
    flex-grow: 1 !important;
    text-align: center !important;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .label-header-form {
    font-size: 16px;
  }

  .form-group label {
    font-weight: 500;
  }

  .lable-sp {
    font-weight: 500;
  }

  .multi-select-wrapper,
  .auto-autosuggest-wrap-component {
    flex: 1 1 auto;
  }

  .border-grey {
    border-color: #e5eaee !important;
  }
}
</style>
