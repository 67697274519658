export const UNIT_OPTIONS = [
  {
    id: null,
    name: 'Chọn đơn vị tính',
  },
  {
    id: 1,
    name: 'Cái',
  },
  {
    id: 2,
    name: 'Bộ',
  },
  {
    id: 3,
    name: 'Gói',
  },
];
